@font-face {
  font-family: Futura;
  src: url('./assets/fonts/Futura-Bold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: Futura;
  src: url('./assets/fonts/Futura-Heavy.ttf');
  font-weight: bold;
}
html,
body {
  height: 100%;
}

.boxfix{
  grid-template-columns: 1fr 2fr;
}

.pdfDiv {
  margin: 0 auto;
  max-width: 900px;
  max-height: 700px;
}

.certificateImg {
  max-width: 900px;
}

@media only screen and (max-width: 800px) {
  .pdfDiv {
    height: 300px !important;
  }
}

/*** certificateFormPage ***/
.certificateFormPage {
}

.certificateFormPage .form-container  {
  max-width: 415px;
  text-align: center;
}

.certificateFormPage .certificate-container {
  text-align: center;
}

.certificateFormPage .form-signin  {
  background-image: url('./assets/dibujos_feria_libro.png');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  padding: 1rem;
  padding-bottom: 140px;
  background-color: white;
  box-shadow: 0px 10px 12px -2px rgba(0,0,0,0.43);
  -webkit-box-shadow: 0px 10px 12px -2px rgba(0,0,0,0.43);
  -moz-box-shadow: 0px 10px 12px -2px rgba(0,0,0,0.43);
  font-family: "Futura", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.certificateFormPage .form-signin .form-floating:focus-within {
  z-index: 2;
}

.certificateFormPage .logos-uas {
  max-width: 400px;
  margin: 10px 0px;
}

.certificateFormPage .logo-feria {
  max-width: 90%;
}

.certificateFormPage .form-signin .instructions {
  display: block;
  padding: 0px 30px;
  font-size: 16px;
  color: #1F143C;
  font-family: Futura;
  font-weight: bold;
  margin-bottom: 20px ;
}

.certificateFormPage .form-signin .btn, .certificate-container .btn {
  color: white;
  font-weight: bold;
  font-family: Futura;
  background-color: #FECB2E;
  border-color: #FECB2E;
  font-size: 20px;
  padding: 5px 30px;
  border-radius: 50px;
}

.certificateFormPage .certificate-container .btn {
  font-size: 30px;
  margin-top: 30px;
}

.certificateFormPage .form-signin input {
  background-color: #E9E9E9;
  border-radius: 50px;
  padding: 15px 20px;
  font-size: 16px;
}

.certificateFormPage .form-signin .inputName, .form-signin .inputEmail {
  margin-bottom: 20px;
  padding: 0px 20px;
}


/*** certificateValidatePage ***/
.certificateValidatePage {
  font-family: "Futura", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.certificateValidatePage .certificate-validate-container {
  text-align: center;
  background: url('./assets/validation-page/certificate_validate_bg.png') no-repeat;
  background-size: contain;
  width: 410px;
  height: 570px;
  margin-top: 70px;
}

.certificateValidatePage .logos-uas {
  max-width: 400px;
  margin: 44px 0px;
}

.certificateValidatePage .certificate-validate-data {

}

.certificateValidatePage .certificate-validate-data h2 {
  font-size: 18px;
  margin: 30px 0px;
  color: #1B3F7E;
  font-weight: 600;
}

.certificateValidatePage .certificate-validate-data .data-field {
  display: block;
  position: relative;
  padding-top: 16px;
  margin-bottom: 20px;
}
.certificateValidatePage .certificate-validate-data .data-field .tag {
  display: block;
  background: url('./assets/validation-page/tag.png') no-repeat;
  background-size: contain;
  width: 200px;
  height:40px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  padding-top: 2px;
  padding-left: 10px;
  position: absolute;
  z-index: 2;
  top: 0;
}

.certificateValidatePage .certificate-validate-data .data-field .tag .icon {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height:20px;
}

.certificateValidatePage .certificate-validate-data .data-field .tag .icon-user {
  background: url('./assets/validation-page/user-icon.png') no-repeat;
  background-size: contain;
}
.certificateValidatePage .certificate-validate-data .data-field .tag .icon-date {
  background: url('./assets/validation-page/date-icon.png') no-repeat;
  background-size: contain;
}
.certificateValidatePage .certificate-validate-data .data-field .tag .icon-conference {
  background: url('./assets/validation-page/conference-icon.png') no-repeat;
  background-size: contain;
}

.certificateValidatePage .certificate-validate-data .data-field .tag .tag-value {
  display: inline-block;
  vertical-align: middle;
  margin-left: 80px;
}

.certificateValidatePage .certificate-validate-data .data-field .value{
  display: block;
  font-size: 16px;
  padding: 15px 0px;
  color: #1B3F7E;
  font-weight: 600;
  background-color: #F0EBE0;
  z-index: 1;
  margin-top: 10px;
}

.form-fiuc2024 .form-signin {
  margin-top: 20px;
  background: url('./assets/fondo_fiuc2024.jpg') no-repeat;
  background-size: cover;
}


.form-fiuc2024 .instructions {
  margin-top: 233px;
  color: white !important;
}

.form-fiuc2024 .form-signin .btn, .form-fiuc2024 .btn {
  background-color: #003299;
  border-color: #003299;
}


.form-feliuas2025 {
  background-image: url('./assets/feliuas2025/dibujos_feria_libro2025.png') !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.form-feliuas2025 .logo-feria{
  display: block;
  max-width: 100%;
  height: auto;
}